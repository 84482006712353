<template>
  <div class="disa">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="年级:" prop="grade">
          <el-select
            :disabled="isEdit || isLook"
            @change="handleChangeGrade"
            v-model="ruleForm.grade"
            filterable
            placeholder="请选择或搜索年级"
          >
            <el-option
              v-for="item in level_li"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="班级:" prop="class">
          <el-select
            :disabled="isEdit || isLook"
            @change="handleChangeClass"
            v-model="ruleForm.class"
            filterable
            placeholder="请选择或搜索班级"
          >
            <el-option
              v-for="item in classlist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学生姓名:" prop="name">
          <el-select
            :disabled="isEdit || isLook"
            v-model="ruleForm.student_id"
            filterable
            clearable
            placeholder="请选择或搜索学生姓名"
          >
            <el-option
              v-for="item in studentlist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="aa flexSt"
          style=""
          label="体艺二加一:"
          prop="name"
        >
          <img
            v-show="!isLook"
            @click="handleAddList"
            class="add_btn"
            src="@/assets/img/219.png"
            alt=""
          />

          <div
            :ref="'box' + index"
            class="list"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="flexSt box">
              <img
                v-show="!isLook"
                @click="handleDelList(index, item)"
                v-if="list.length > 1"
                class="add_btn add_btn1"
                src="@/assets/img/220.png"
                alt=""
              />

              <el-form-item
                style="width: 100%"
                label="名称:"
                :prop="index + '.sport_name'"
                class="flexSt"
                :rules="[
                  {
                    required: true,
                    message: '请输入体艺二加一名称，例如：篮球',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :disabled="isLook"
                  placeholder="请输入体艺二加一名称，例如：篮球"
                  v-model="item.sport_name"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item class="flexSt" label="分数:">
              <el-input
                :disabled="isLook"
                placeholder="请输入分数"
                v-model="item.score"
              ></el-input>
            </el-form-item>
            <el-form-item class="flexSt" label="等级:">
              <el-input
                :disabled="isLook"
                placeholder="请输入等级"
                v-model="item.level"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="认定时间:"
              :prop="index + '.time'"
              class="flexSt"
              :rules="[
                {
                  required: true,
                  message: '请选择日期',
                  trigger: 'blur',
                },
              ]"
            >
              <el-date-picker
                :disabled="isLook"
                v-model="item.time"
                type="date"
                placeholder="请选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="证件照片:" class="flexSt ab">
              <el-upload
                :disabled="isLook"
                class="avatar-uploader"
                :headers="headers"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (file, fileList) => onSuccess(file, fileList, index)
                "
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="item.photo" :src="item.photo" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">
                  图片格式为JPG或PNG，文件大小为1M以内
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item class="flexSt" label="备注:">
              <el-input
                :disabled="isLook"
                placeholder="请输入备注"
                v-model="item.remark"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handlePhysicalArts">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addPhysicalArts, editPhysicalArts } from "@/api/classroom";
import { getUser } from "@/utils/auth";
import { baseUrl } from "@/utils/config";
export default {
  data() {
    return {
      title: "",
      ruleForm: {
        grade: "",
        class: "",
        name: "",
      },
      rules: {
        grade: [
          { required: true, message: "请选择或搜索年级", trigger: "blur" },
        ],
        class: [
          { required: true, message: "请选择或搜索班级", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请选择或搜索学生姓名", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      level_li: [],
      classlist: [],
      list: [
        {
          sport_name: "",
          score: "",
          level: "",
          photo: "",
          remark: "",
          time: "",
        },
      ],
      imageUrl: "",
      userInfo: {},
      studentlist: [],
      grade: "",
      action: baseUrl + "/upload",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      isEdit: false,
      isLook: false,
      delId: [],
    };
  },
  methods: {
    onSuccess(file, fileList, index) {
      this.list[index].photo = file.data[0].path;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!或者PNG格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return isLt1M;
    },
    // 切换年级
    handleChangeGrade(tab, event) {
      this.ruleForm.grade = tab;
      this.grade = tab; // 同步年级id
      this.getClasses();
      this.ruleForm.student_id = "";
    },
    async getConfig() {
      const res = await this.axios.get("/config/level");
      if (res.code === 1) {
        this.level_li = res.data;
        this.ruleForm.grade = res.data[0].id;
        this.grade = res.data[0].id.toString();
        this.getClasses();
      }
    },
    handleChangeClass(val) {
      console.log(val);
      this.ruleForm.class = val;
      this.ruleForm.student_id = "";
      this.getStudent();
    },
    // 获取班级
    async getClasses(id) {
      const res = await this.axios.get(
        `/classes?all=1&school_id=${this.userInfo.school_id}&level_id=${this.ruleForm.grade}`
      );
      if (res.code === 1) {
        this.classlist = res.data;
        if (res.data.length > 0) {
          if (id) {
            this.ruleForm.class = id;
            this.getStudent();
          } else {
            this.ruleForm.class = res.data[0].id;
            this.currentClassInfo = Object.assign({}, res.data[0]);
            this.getStudent();
          }
        }
      }
    },
    // 获取班级下学生数据
    async getStudent() {
      const res = await this.axios.get(
        `/students/${this.ruleForm.class}?per_page=99999&page=1`
      );
      if (res.code === 1) {
        this.studentlist = res.data.data;
      }
    },
    handlePhysicalArts() {
      if (this.type == 1) {
        this.handleAddaddPhysicalArts();
      } else if (this.type == 3) {
        this.handleClose();
      } else if (this.type == 2) {
        this.handleEditPhysicalArts();
      }
    },
    async handleEditPhysicalArts() {
      let data = {
        school_id: getUser().school_id,
        student_id: this.ruleForm.student_id,
        data: this.list,
        destroy: this.delId,
      };
      await editPhysicalArts(data).then((res) => {
        this.$message.success("编辑成功");
        this.dialogVisible = false;
        this.$emit("handleGetphysicalArts");
      });
    },
    async handleAddaddPhysicalArts() {
      let data = {
        school_id: getUser().school_id,
        student_id: this.ruleForm.student_id,
        data: this.list,
      };
      await addPhysicalArts(data).then((res) => {
        this.$message.success("添加成功");
        this.dialogVisible = false;
        this.$emit("handleGetphysicalArts");
      });
    },

    show(type, val) {
      this.type = type;
      if (type == 1) {
        this.title = "体艺二加一 -新增";
      } else if (type == 2) {
        this.title = "体艺二加一 -编辑";
        this.ruleForm = JSON.parse(val);
        this.ruleForm.class = JSON.parse(val).classes_id;
        this.ruleForm.student_id = JSON.parse(val).id;
        this.list = JSON.parse(val).physicalArts;
        this.isEdit = true;
      } else if (type == 3) {
        this.title = "体艺二加一 -查看";
        this.ruleForm = { ...val };
        this.ruleForm.class = val.classes_id;
        this.ruleForm.student_id = val.id;
        this.list = val.physicalArts;
        this.isLook = true;
      }
      this.userInfo = getUser();
      this.getConfig();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.list = [
        {
          sport_name: "",
          score: "",
          level: "",
          photo: "",
          remark: "",
          time: "",
        },
      ];

      this.ruleForm = {
        grade: "",
        class: "",
        name: "",
      };
      this.$emit("handleGetphysicalArts");
    },
    handleDelList(index, item) {
      if (
        item.sport_name ||
        item.score ||
        item.level ||
        item.time ||
        item.photo ||
        item.remark
      ) {
        this.$confirm("删除后将需要重新填写，确认删除该条数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.list.splice(index, 1);
            if (this.type == 2) {
              if (item.id) {
                this.delId.push(item.id);
              }
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleAddList() {
      this.list.push({
        sport_name: "",
        score: "",
        level: "",
        photo: "",
        remark: "",
        time: "",
      });
      this.$nextTick(() => {
        this.$refs["box" + (this.list.length - 1)][0].scrollIntoView();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}
.avatar-uploader {
  .el-upload:hover {
    border-color: #409eff;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
/deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
}
/deep/.aa {
  align-items: flex-start;
  .el-form-item__content {
    margin-left: 30px !important;
    margin-top: 10px;
    width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
}
// /deep/ .el-form-item__content {
//   margin-left: 0 !important;
// }
.ab {
  align-items: flex-start;
}
.list {
  width: 100%;
  border-radius: 4px;
  background-color: rgba(247, 248, 250, 1);
  margin-top: 21px;
}
.add_btn {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.box {
  width: 100%;
  position: relative;
  .add_btn1 {
    position: absolute;
    left: 20px;
  }
}

/deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
}
/deep/.el-select,
/deep/ .el-input__inner,
/deep/.el-date-editor,
/deep/ .el-input {
  width: 100%;
}
</style>
