import { baseUrl } from "@/utils/config";
import request from "@/utils/request";
// 班级列表
export function classes(data) {
  return request({
    url: baseUrl + "/classes",
    method: "get",
    params: data,
  });
}
// 课堂风采列表
export function gallery(data) {
  return request({
    url: baseUrl + "/gallery",
    method: "get",
    params: data,
  });
}
// 批量删除
export function delete_all(data) {
  return request({
    url: baseUrl + "/gallery/delete_all",
    method: "post",
    data: data,
  });
}

// 课堂记录列表
export function courses(data) {
  return request({
    url: baseUrl + "/courses",
    method: "get",
    params: data,
  });
}

// 训练记录列表
export function coursesshow(data) {
  return request({
    url: baseUrl + "/courses/show/" + data.course_id,
    method: "get",
    params: data,
  });
}
// 成绩列表
export function coursesproject(data) {
  return request({
    url: baseUrl + "/courses/project/items/" + data.project_id,
    method: "get",
    params: data,
  });
}

// 考勤记录
export function recording(data) {
  return request({
    url: baseUrl + "/courses/recording/show/" + data.course_id,
    method: "get",
    params: data,
  });
}
// 考勤列表
export function recordingpage(data) {
  return request({
    url: baseUrl + "/courses/recording/page",
    method: "get",
    params: data,
  });
}

// 体艺二加一
// 列表
export function physicalArts(data) {
  return request({
    url: baseUrl + "/physicalArts",
    method: "get",
    params: data,
  });
}

// 添加
export function addPhysicalArts(data) {
  return request({
    url: baseUrl + "/physicalArts",
    method: "post",
    data: data,
  });
}
// 编辑
export function editPhysicalArts(data) {
  return request({
    url: baseUrl + "/physicalArts",
    method: "put",
    data: data,
  });
}

// 编辑
export function delPhysicalArts(data) {
  return request({
    url: baseUrl + "/physicalArts",
    method: "DELETE",
    data: data,
  });
}

// 教师出勤
// 列表
export function teacherCourseAttendance(data) {
  return request({
    url: baseUrl + "/teacherCourseAttendance",
    method: "get",
    params: data,
  });
}

// 学生出勤
// 列表
export function studentCourseAttendance(data) {
  return request({
    url: baseUrl + "/studentCourseAttendance",
    method: "get",
    params: data,
  });
}
